































































































































































































































































































































































































































.ws-table::v-deep {
  tr .qrcode {
    position: relative;
    transform: scale(1);
    transform-origin: 0 0;
    background-color: #fff;
    padding: 0.2rem;
    border-radius: 0.1rem;
    transition: transform .2s, z-index .4s;
    z-index: 1;
  }

  tr:nth-last-child(-n+3) .qrcode {
    transform-origin: left bottom;
  }

  tr .qrcode:hover {
    transform: scale(6);
    transition: transform .2s, z-index 0s;
    z-index: 9;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
}
